.pickerBox {
	width: 100%;
	margin-bottom: 40px;
	font-size: 14px;
	position: relative;
	color: #000;
	padding-bottom: 4px;


	&__picker {
		width: 100%;
	}

	&__error {
		color: var(--base-red);
		position: absolute;
		left: 0;
		bottom: -55%;
		font-weight: 100;
		padding: 4px;
    font-size: 12px;
	}

	&__error-border {
		.ant-picker {
			border-color: var(--base-red) !important;
		}
	}
}