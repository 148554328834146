@import '../../styles/abstracts/mixins';

.layout {
	color: var(--text-color);

	.ant-menu-item-selected {
		background-color: var(--background-color-selected-item-sidebar) !important;
		color: var(--layout-color-menu-item) !important;
		position: relative;

		span {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background-color: var(--base-white);
			}
		}
	}

	.ant-menu-item-active {
		background-color: inherit !important;
		color: var(--base-white) !important;
	}

	.ant-menu-vertical {
		span#item-title {
			display: none;
		}
	}

	.ant-layout-sider-collapsed {
		h1#company-title {
			display: none;
		}
	}

	&__slider {
		background-color: var(--background-color-sidebar) !important;
		box-shadow: 0 0 10px rgba(0,0,0,0.5);
	}

	&__logo-box {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 6.5rem;
		border-bottom: 1px solid #2a3547;
		h1 {
			font-size: 1.8rem;
			color: var(--base-white);
			margin-left: 2.5rem;
		}
	}
	
	&__logo {
		width: 5rem;
		height: 5rem;
	}

	&__menu {
		color: inherit !important;
		background: inherit !important;
		border-right: none !important;
		overflow-y: scroll;
		height: calc(100vh - 6.5rem - 1rem); // height of logo box + margin
		margin-top: 1rem !important;
	}

	&__menu-item {
		display: flex !important;
    align-items: center;
    color: var(--layout-color-menu-item);

    i {
      color: var(--layout-color-menu-item);
    }

		a {
			color: inherit;
		}

		&:hover {
			color: var(--base-white) !important;
		}
	}

	&__icon-box {
    margin-right: 1rem;
  }

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
    background: var(--base-white) !important;
		font-size: 1.5rem;
		color: var(--text-color);
		padding: 0 4rem !important;
		height: auto !important;

    @include breakpoint(sm) {
      padding: 0 1rem !important;
    }

    .anticon {
      @include breakpoint(sm) {
        display: none;
      }
    }
	}
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  display: none;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  margin: 3px auto 0 auto !important;
  padding: 0.7rem 1rem !important;
  height: auto !important;
}

.ant-menu-item .layout__icon-box {
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-menu-item > span {
  padding-top: 4px !important;
}

.ant-btn {
  padding: 0 10px !important;
}

.ant-btn-danger {
  color: var(--base-white) !important;
  background-color: var(--base-mandy) !important;
  border-color: var(--base-mandy) !important;
}

.ant-tag-green {
  color: var(--base-lima) !important;
  background: var(--disabled-button-background-color) !important;
  border-color: var(--base-lima) !important;
}

.btn-success {
  background: rgba(82, 196, 26, 0.1) !important;
  color: var(--base-lima) !important;
  border-color: var(--base-lima) !important;

  &:hover {
    background: rgba(82, 196, 26, 0.8) !important;
    color: var(--background-color) !important;
  }
} 

.ant-card-bordered {
  border: none;
}

.ant-menu-item > i {
  margin-left: -6px !important;
}

#root > section > aside > div > ul > li {
  height: 40px !important;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 2rem !important;
}

.user__table .ant-table-body {
  border-top: 1px solid var(--card-border-color) !important;
  border-radius: 0 3px 0 0;
}