:root {
	--base-white: #fff;
	--base-black: #000;
	--base-red: #e11;
	--auth-container-background: #e9e6e6;

  --base-mandy: #ec536c;
  --base-lima: #52c41a;
  --base-oxford-blue: #2d3b48;

	--antd-active-background: rgb(35, 144, 255);
	--icon-colors: rgba(0, 0, 0, 0.25);

	--background-color: #fff;
	--text-color: #000;
	--ant-menu-color: #fff;
	--layout-color-logo: #4b545c;
	--layout-color-menu-item: #c2c7d0;
	--header-color-link: #16181b;
	--header-background-color-link: #f8f9fa;
	--header-border-color-side-dropdown-menu: rgba(0,0,0,.15);
	--active-link-color: rgb(35, 144, 255);
	--theme-color: #1890ff;
	--card-border-color: #d9d9d9;
	--input-text-background: #fff;
	--input-text-color: rgba(0, 0, 0, 0.65);
	--input-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	--background-color-layout: #F5F7FC;
	--background-color-header: #fff;
	--active-hover-item: rgba(255,255,255,.1);
	--background-color-sidebar: #222c3c;
	--background-color-selected-item-sidebar: #1d2531;
	--authentication-page-background: #1fc8db;
	--authentication-page-gradient: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
}
